<template>
  <div class="sandbox">
    <div class="left">
      <a-select
        v-model="form.select"
        style="width: 100%"
        @change="leftSelectBtn(form.select)"
      >
        <a-select-option
          v-for="item in arrayObj.leftSelect"
          :key="item.id"
          :value="item.title"
        >
          {{ item.title }}
        </a-select-option>
      </a-select>
      <a-card
        v-for="(item, index) in docModuleList"
        :key="index"
        :title="item.module"
        style="width: 100%; margin-top: 20px"
      >
        <p
          class="ff-list"
          :class="{ active: e.summary == leftActive }"
          v-for="(e, i) in item.docItems"
          :key="i"
          @click="selectButton(e, i)"
        >
          {{ e.summary }}
        </p>
      </a-card>
    </div>
    <div class="right">
      <div class="ff-content">
        <h2>
          <a-icon type="bars" style="margin-right: 10px" /><span>{{
            docItems.summary
          }}</span>
        </h2>
        <div class="xian"></div>
        <div class="ff-url" style="margin-bottom: 16px;">
          <div class="url-name">接口名</div>
          <div class="url-url">
            <a-tag color="green" v-for="item in docItems.httpMethodList" :key="item">
              {{ item }}
            </a-tag>
            {{docItems.name}}
          </div>
          <div class="url-version"></div>
        </div> 
        <div class="xian-title"><span>接口描述</span></div>
        <p style="margin: 10px 0">{{ docItems.summary }}</p>
        <div class="ff-block">
          <div class="xian-title"><span>请求地址</span></div>
          <a-table
            :columns="columnsIP"
            :data-source="ipArr"
            bordered
            :pagination="false"
          ></a-table>
        </div>
        <div class="ff-block" v-if="docItems.method">
          <div class="xian-title"><span>请求方法</span></div>
          <p>{{ docItems.method }}</p>
        </div>
        <div class="ff-block">
          <div class="xian-title"><span>公共参数</span></div>
          <a-table
            :columns="columnsParams"
            :data-source="publiceParams"
            bordered
            :pagination="false"
          ></a-table>
        </div>
        <div class="ff-block">
          <div class="xian-title"><span>业务参数</span></div>
          <a-table
            :columns="columnsParams"
            :data-source="docItems.requestParameters"
            bordered
            :pagination="false"
          ></a-table>
        </div>
        <div class="ff-block">
          <div class="xian-title"><span>Body参数</span></div>
          <a-table
            :columns="columnsParams"
            :data-source="docItems.requestBodyParameters"
            bordered
            :pagination="false"
          ></a-table>
        </div>
        <!-- <div class="ff-block">
          <div class="xian-title"><span>公共响应参数</span></div>
          <a-table
            :columns="columnsParams"
            :data-source="publiceResponseParams"
            bordered
            :pagination="false"
          ></a-table>
        </div> -->
        <div class="ff-block">
          <div class="xian-title"><span>业务响应参数</span></div>
          <a-table
            :columns="columnsParams"
            :data-source="docItems.responseParameters"
            bordered
            :pagination="false"
          ></a-table>
        </div>
        <div class="ff-block">
          <div class="xian-title"><span>响应示例</span></div>
          <pre v-if="docItems.codeObj">{{ docItems.codeObj.json }}</pre>
        </div>
        <div class="ff-block">
          <div class="xian-title"><span>异常示例</span></div>
          <pre v-if="docItems.codeObj">{{ docItems.codeObj.errorJson }}</pre>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const columns = [
  {
    title: "参数",
    dataIndex: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "值",
    className: "column-money",
    dataIndex: "money",
    scopedSlots: { customRender: "value" },
  },
];

const columnsIP = [
  {
    title: "环境",
    dataIndex: "title",
  },
  {
    title: "请求地址",
    dataIndex: "ip",
  },
];

const columnsParams = [
  {
    title: "参数",
    dataIndex: "name",
  },
  {
    title: "类型",
    dataIndex: "type",
  },
  {
    title: "是否必填",
    dataIndex: "required",
  },
  {
    title: "最大长度",
    dataIndex: "maxLength",
  },
  {
    title: "描述",
    dataIndex: "description",
  },
  {
    title: "示例值",
    dataIndex: "x-example",
  },
];

const data = [
  {
    key: "1",
    name: "John Brown",
    money: "￥300,000.00",
    address: "New York No. 1 Lake Park",
  },
  {
    key: "2",
    name: "Jim Green",
    money: "￥1,256,000.00",
    address: "London No. 1 Lake Park",
  },
];
import { getDocBaseInfoInfactApi, docinfoOrderApi } from "@/serve/Interface"
import { createResponseCode, buildChildrenTreeData } from "@/tool"
export default {
  data() {
    return {
      ipArr: [{ key: 1, title: "正式环境", ip: "https://osapi.ecmoho.com" }],
      publiceParams: [
        {
          key: 0,
          name: "token",
          type: "String",
          required: "是",
          maxLength: 32,
          description: "登录后接口返回",
          "x-example": "4a897bac3d5a46817af509c2f18647f2",
        }
      ],
      publiceResponseParams: [
        {
          key: 0,
          name: "code",
          type: "String",
          required: "是",
          maxLength: "-",
          description: "网关返回码,详见文档",
          "x-example": "40004",
        },
        {
          key: 1,
          name: "msg",
          type: "String",
          required: "是",
          maxLength: "-",
          description: "网关返回码,详见文档",
          "x-example": "40004",
        },
        {
          key: 2,
          name: "sub_code",
          type: "String",
          required: "否",
          maxLength: "-",
          description: "业务返回码，参见具体的API接口文档",
          "x-example": "ACQ.TRADE_HAS_SUCCESS",
        },
        {
          key: 3,
          name: "sub_msg",
          type: "String",
          required: "否",
          maxLength: "-",
          description: "业务返回码描述，参见具体的API接口文档",
          "x-example": "交易已被支付",
        },
      ],
      form: {
        select: "",
      },
      arrayObj: {
        leftSelect: [],
      },
      docModuleList: [],
      docItems: {},
      data,
      columns,
      columnsIP,
      columnsParams,
      leftActive: "",
    };
  },
  mounted() {
    getDocBaseInfoInfactApi().then((res) => {
      this.ipArr[0].ip = res.urlProd;
      this.arrayObj.leftSelect = res.docInfoList || [];
      this.form.select = this.arrayObj.leftSelect[0].title;
      this.getDocinfoOrder(this.form.select);
    });
  },
  methods: {
    leftSelectBtn(title) {
      this.getDocinfoOrder(title);
    },
    getDocinfoOrder(name) {
      docinfoOrderApi(name).then((res) => {
        this.docModuleList = res.docInfactModuleList;
        this.docModuleList.forEach((res, index) => {
          if (index == 0) {
            this.leftActive = res.docItems.length && res.docItems[0].summary
            this.docItems = res.docItems.length && res.docItems[0]
            this.docItems.codeObj = createResponseCode(this.docItems)
          }
          this.arrangement(res.docItems);
        });
      });
    },
    arrangement(docItems) {
      docItems.forEach((res, index) => {
        buildChildrenTreeData(res.responseParameters);
        // res.responseParameters.forEach((e, i) => {
        //   e.key = i
        //   if (e.refs) {
        //     e.children = e.refs;
        //     e.children.forEach((r, m) => {
        //       r.key = "children" + m;
        //     });
        //   }
        // });
        res.requestParameters.forEach((e, i)=>{
          e.key = i
        });
        if (res.requestBodyParameters) {
          buildChildrenTreeData(res.requestBodyParameters);
        }
      });
    },
    selectButton(e, i) {
      this.docItems = e
      this.leftActive = e.summary
      window.scrollTo(0, 0)
      this.docItems.codeObj = createResponseCode(this.docItems)
    }
  },
};
</script>
<style lang="scss">
.sandbox {
  width: 1200px;
  height: calc(100% - 60px);
  margin: 0 auto;
  display: flex;
  .left {
    width: 240px;
    padding-top: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    .ff-list {
      cursor: pointer;
      &.active {
        color: #1890ff;
      }
    }
  }
  .right {
    width: calc(100% - 240px);
    border-left: 1px solid #eee;
    padding-top: 20px;
    padding-left: 20px;
    .xian {
      width: 100%;
      height: 1px;
      background: #eee;
      margin: 20px 0;
    }
    .xian-title {
      position: relative;
      font-size: 20px;
      margin: 20px 0;
      span {
        position: relative;
        z-index: 2;
        background: #fff;
        padding: 0 5px;
        margin: 0 20px;
      }
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background: #eee;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
  .ff-set {
    margin: 20px 0;
  }
}
</style>
